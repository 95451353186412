const report = [
	{
		path:'/AssetReport/category',
		component:()=>import('@/views/Asset/Report/Category.vue'),
	},{
		path:'/AssetReport/user',
		component:()=>import('@/views/Asset/Report/User.vue'),
	},{
		path:'/AssetReport/dprn',
		component:()=>import( '@/views/Asset/Report/Dprn.vue'),
	},{
		path:'/AssetReport/record',
		component:()=>import( '@/views/Asset/Report/AssetRecord.vue'),
	},{
		path:'/AssetReport/disposal',
		component:()=>import( '@/views/Asset/Report/Disposal.vue'),
	},{
		path:'/AssetReport/waitReturn',
		component:()=>import( '@/views/Asset/Report/WaitReturn.vue'),
	},{
		path:'/AssetReport/DimissionStaff',
		component:()=>import( '@/views/Asset/Report/DimissionStaff.vue'),
	},{
		path:'/Assetreport/idle',
		component:()=>import( '@/views/Asset/Report/IdleAsset.vue'),
	},{ 
		path:'/CseReport/storage',
		component:()=>import( '@/views/Asset/Report/CseStorage.vue'),
	},{
		path:'/CseReport/record',
		component:()=>import( '@/views/Asset/Report/CseRecord.vue'),
	},{
		path:'/CseReport/disposal',
		component:()=>import( '@/views/Asset/Report/CseDisposal.vue'),
	},{
		path:'/CseReport/receive/',
		component:()=>import( '@/views/Asset/Report/CseReceive.vue'),
	},{
		path:'/CseReport/expired/',
		component:()=>import( '@/views/Asset/Report/CseExpired.vue'),
	},{
		path:'/CseReport/outStorage',
		component:()=>import( '@/views/Asset/Report/CseOutStorage.vue'),
	}		 	
]

export default report
