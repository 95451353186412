export  default{
    //type:0(年月日)  1：年月日+时分秒
    getDate:function(date='',str='/',type=0){
        let result = date.getFullYear()+str+(date.getMonth() + 1)+ str + date.getDate();
        if(type == 1){
            result =  result +' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
        }
        return result;
    },

    getId:function(arr){
        var id=1  
        if(arr.length > 0){
             var max_num  = Math.max.apply(Math,arr.map(function(item) {return item.id}))
            id = max_num+1
        }
       return id
    }
}