import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import NotFoundComponent from '@/views/404';
import asset from './asset'
import report from './report'
Vue.use(VueRouter)
const routes = [
	{
	  path: '/',
	  name: 'Login', 
	  component: Login,
	  meta:{
	    title:'物资管理系统'
	  }
	},
	{
	  path:'/layout',
	  name:'layout',
	  meta:{
	    title:'物资管理系统'
	  },
	  component: () => import(/* webpackChunkName: "layout" */ '@/views/Layout'),
	  children:[
		  ...asset,
		  ...report,
		  {
		    path:'/Index/index',
		  		component:()=>import('@/views/Asset/Overview.vue'),
		  },{
			path:'/admin/index',
			component: () => import('@/views/Admin/Index.vue')
		  },{
			path:'/role/index',
			component:() => import('@/views/Role/Index')
		  },{
			path:'/Menu/index',
			component:() => import('@/views/Menu/Index')
		  },{
			path:'/Company/index', 
			component:()  => import('@/views/Company/Index.vue') 
		  },{
			path:'/SysLog/index',
			component:() => import('@/views/SysLog')
		  },{
			path:'/SysConfig/index',
			component:() => import('@/views/Config')
		  },{
			path:'/Department/index',
			component:()=>import('@/views/Department/Index')
		  },{
			path:'/Backlog/index',
			component:()=>import('@/views/BackLog/Index.vue')
		  },{
			path:'/Doc/index',  
			component:()=>import('@/views/Doc/Index.vue') 
		  },{
			path:'/DataBak/index',  
			component:()=>import('@/views/DataBackup/Index.vue') 
			},{
		    path:'/SysMessage/index',
		    component:()=>import( '@/views/SysMessage')
		  }, {
			path:'/Appmessage/index',
			component:()=>import('@/views/AppMsg')
		  }, {
			path:'/Appmessage/index',
			component:()=>import('@/views/AppMsg')
		  },{
			path:'/MiniProgram/index',
			component:()=>import('@/views/Config/MiniProgram.vue')
		  },
		]
	}
]

const router = new VueRouter({ 
  // mode: 'history',   
  base: process.env.BASE_URL, 
  routes:routes
}) 
export default router