import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'      
import ElementUI from 'element-ui'    //系统后台ui框架
import 'element-ui/lib/theme-chalk/index.css'
// import {Button,Row,Col,Tab, Tabs, Collapse, CollapseItem,Divider,Popup,Field,Icon  } from 'vant'
import common from '../common/common'
import axios  from 'axios'
import qs from 'qs'
import $ from 'jquery'
import api  from './utils/apiaxios'
import pagination from '../common/Pagination.vue'
import BenzAMRRecorder from 'benz-amr-recorder'
import '@/assets/iconfont/iconfont.css'   
import  Vconsole from 'vconsole'
Vue.config.productionTip = false
// let vConsole  = new Vconsole()
// Vue.use(vConsole)  
Vue.use(ElementUI)

// Vue.html2canvas =  html2canvas 
// Vue.use(Button)
// Vue.use(Tab)
// Vue.use(Tabs)
// Vue.use(Col)
// Vue.use(Collapse) 
// Vue.use(CollapseItem) 
// Vue.use(Divider )
// Vue.use(Row)
// Vue.use(Popup)
// Vue.use(Field)
// Vue.use(Icon)
Vue.prototype.common =  common
Vue.prototype.$axios = axios
Vue.prototype.$qs =  qs
Vue.prototype.$api =  api
Vue.prototype.$imgDomain =  ""    //图片访问地址拼接
Vue.prototype.$uploadUrl = "/api/admin/Upload/images"      //上传接口
// Vue.prototype.$echarts= echarts
window.jQuery = $
window.$ =  $

// Vue.prototype.$wxconfig =  wxconfig
// window.wx =  wxJsSdk
Vue.component('pagination-view',pagination)
	console.log('=====')
router.beforeEach((to,from,next)=>{   //地址title

  if(to.meta.title){
    document.title = to.meta.title
    // document.title = to.meta.title??'艺星企业微信应用后台'
  }else{
    document.title  ='物资管理系统'
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
