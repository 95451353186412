<template>
    <div>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pagesize"
            layout="total,sizes,prev,pager,next,jumper"
            :total="total">
        </el-pagination>     
    </div>
</template>
<script>
export default {
    // props:['tableDataTotal','tableDataLimit'],
	props:{
		tableDataTotal:{
			type:Number,
			default(){
				return 0
			}
		},
		tableDataLimit:{
			type:Number,
			default(){
				return 0
			}
		}
	},
   data() {
      return {
           currentPage:1,
           pagesize:10,
           total:0
      }
   },
   watch:{
	   tableDataTotal:{
		   handler:function(newV){
			    this.total =  newV
				this.onload()
		   },
		   immediate: true,
	   },
    //    tableDataTotal:function(val){
    //        this.total =  val
    //        this.onload()
    //    },
		tableDataLimit:{
			handler:function(val){
				this.pagesize =  val
				this.onload()	
			},
			immediate: true,
		} 
   },
   created(){
       
   },
   mounted(){
   },
   methods:{
    onload(){
        this.total =  this.total
    },

    handleSizeChange(val){ 
        this.$emit('pageSize',val)
        // console.log('每页条数：'+val)    
        this.pagesize =  val
            
    },
    handleCurrentChange(val){
        this.$emit('currPage',val)
        // console.log('当前页:'+val)
        this.currentPage =  val
    }
   }

}
</script>
<style scoped>
</style>