const asset = [
	{
		path:'/Asset/overview',
		component:()=>import( '@/views/Asset/Overview.vue'),
	},{
	  path:'/AssetCategory/index',
	  component:()=>import( '@/views/Asset/Category/Index.vue'),
	  meta:{
	  	category_type:1
	  }
	},{
	  path:'/AssetLocation/index',
	  component:()=>import('@/views/Asset/Location/Index.vue'),
	  meta:{
	  	location_type:1
	  }
	},{
	  path:'/AssetList/index',
	  component:()=>import( '@/views/Asset/AssetList/Index.vue')
	},{
	  path:'/AssetCode/index',
	  component:()=>import('@/views/Asset/AssetCode.vue'),
	  meta:{
	  	code_type:1 
	  }
	},{
	  path:'/AssetCard/index',
	  component:()=>import( '@/views/Asset/Card/Index.vue')
	},{
	  path:'/AssetForm/index',
	  component:()=>import( '@/views/Asset/Form/Index.vue'),
	   meta:{
	   		form_type:2
	   }
	},{
	  path:'/AssetLabel/index',
	  component:()=>import('@/views/Asset/Label/Index.vue')
	},{
	  path:'/AssetStorage/index',
	  component:()=>import( '@/views/Asset/StorageIndex.vue')
	},{
	  path:'/AssetDist/index',
	  component:()=>import('@/views/Asset/Dist/Index.vue')
	},{
	  path:'/AssetChangeUser/index',
	  component:()=>import('@/views/Asset/ChangeUserIndex.vue')
	},{
	  path:'/AssetDisposal/index',
	  component:()=>import('@/views/Asset/DisposalIndex.vue')
	},{
	  path:'/AssetMaintain/index',
	  component:()=>import( '@/views/Asset/MaintainIndex.vue')
	},{
	  path:'/AssetLoan/index',
	  component:()=>import('@/views/Asset/Loan/Index.vue')
	},{
	  path:'/AssetAllot/index',
	  component:()=>import('@/views/Asset/AllotIndex.vue') 
	},{
	  path:'/AssetBatch/index',
	  component:()=>import('@/views/Asset/BatchIndex.vue') 
	},{
	  path:'/AssetDiscovery/index',
	  component:()=>import('@/views/Asset/Discovery/Index.vue') 
	},{
	  path:'/AssetDprn/index',
	  component:()=>import('@/views/Asset/Dprn/Index.vue')
	},{
	  path:'/Consumable/form',
	   component:()=>import('@/views/Asset/Form/Index.vue'),
	   meta:{
	   		form_type:3
	   }
	},{
	  path:'/Staff/setting',
	   component:()=>import('@/views/Asset/Form/Index.vue'),
	   meta:{
	   		form_type:4
	   }
	},{
	  path:'/Consumable/warehouse',
	  component:()=>import('@/views/Asset/Location/Index.vue'),
	   meta:{
		   location_type:2
	   }
	},{
	  path:'/Consumable/category',
	  component:()=>import('@/views/Asset/Category/Index.vue'),
	   meta:{
		   category_type:2
	   }
	},{
	  path:'/Consumable/mtl_code',
	  component:()=>import('@/views/Asset/AssetCode.vue'),
	   meta:{
		   code_type:2
	   }
	},{
	  path:'/CseStorage/index',
	  component:()=>import('@/views/Asset/Consumable/StorageIndex.vue')
	},{
	  path:'/CseDist/index',
	  component:()=>import('@/views/Asset/Consumable/DistIndex.vue')
	},{
	  path:'/CseAllot/index',
	  component:()=>import('@/views/Asset/Consumable/AllotIndex.vue')
	},{
	  path:'/CseDisposal/index',
	  component:()=>import('@/views/Asset/Consumable/DisposalIndex.vue')
	},{
	  path:'/CseStock/index',
	  component:()=>import('@/views/Asset/Consumable/StockIndex.vue')
	},{
		path:'/Consumable/file',
		component:()=>import('@/views/Asset/Consumable/File/Index.vue')
	},{
	  path:'/CseAdjustStock/index',
	  component:()=>import('@/views/Asset/Consumable/AdjustStockIndex.vue')
	},{
	  path:'/CseDiscovery/index',
	  component:()=>import('@/views/Asset/Discovery/Index.vue'),
	  meta:{
	  	 type:2
	  }
	},{
	  path:'/Staff/asset',
	  component:()=>import( '@/views/Asset/Staff/Asset.vue'), 
	},{
	  path:'/Staff/cse',
	  component:()=>import( '@/views/Asset/Staff/Consumable.vue'), 
	},{
		path:'/Asset/staffAsset',
		component:()=>import('@/views/Asset/StaffAsset/Index.vue'), 
	},{
		path:'/AssetApproval/index',
		component:()=>import('@/views/Asset/StaffAsset/Approval.vue'), 
	},{
		path:'/AssetList/details',
		component:()=>import('@/views/Asset/AssetList/Details.vue'), 
	},{
		path:'/Asset/staffIndex',
		component:()=>import('@/views/Asset/staffIndex.vue'), 
	}	
]

export default asset
