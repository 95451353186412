<template>
  <div id="app">
      <!-- <router-view/> -->
	  <router-view v-if="isRouterAlive"></router-view>
     
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <!-- <el-container v-show="!(path === '/login' || path === '/home' || path ==='/')">
        <el-header class="header-nav">
              <el-row :gutter="0">
                  <el-col :span="2" :offset="14"><div><router-link to='/index'>Home</router-link></div></el-col>
                  <el-col :span="2"><div>Movie</div></el-col>
                  <el-col :span="2"><div>Trip</div></el-col>
                  <el-col :span="2"><div><router-link to="/dream">Dream</router-link></div></el-col>
                  <el-col :span="2"><div><router-link to='/login'>Login</router-link></div></el-col>
              </el-row>
        </el-header>
      </el-container> -->
  </div>
</template>
<script>
export default{
  data(){
    return{
      path:'',
      isRouterAlive : true,
      main_title:''
    }
  },
  provide(){
        return {
            reload:this.reload
        }
    },
  mounted(){
    this.path  = this.$route.path
    
    // this.route_title =  this.$route.title
    // console.log('path:'+this.$route.path)

  },
  watch:{
    $route(to,form){
      this.path =  to.path
      this.main_title =  to.meta.title
      // console.log(to.meta.title)
    }
  
  },
  created(){
	  // if(!this.path && this.$route.params.key){		//短链接   查找源地址
			// console.log('short_link_key:'+this.$route.params.key)
			// this.$axios.get('/api/mobileApi/ShortLink',{
			// 	params:{
			// 		short_link_key:this.$route.params.key
			// 	}
			// }).then(res=>{
			// 	  setTimeout(()=>{
			// 		  location.href = res.data.data.url
			// 	  },300)
			// 	// console.log('success')
			// }).catch(err=>{
			// 	console.log(err)
			// })
	  // }
    
   },
  methods:{
       reload(){
          this.isRouterAlive = false
          this.$nextTick(function(){
              this.isRouterAlive =  true
          })
      },
  }
}
</script>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
.el-main{
	    border: 10px solid #f7f9fa ; 
	    background: white; 
		padding: 0px;
} 
body{
  margin:0;
}
#app{
  padding: 0;
  margin: 0;
  // overflow: hidden;
}
html,body,#app{
  height: 100%;
}
.page-container{
	padding: 15px;
}
.header-nav{
  line-height: 60px;
}

.page-desc{
  background: white;
  padding: 15px;
  color: #ff1028;
  border: 1px solid #ff1028;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 12px;
}

//上传图片样式
.image-uploader{
    .el-upload{
        border: 1px dashed #d9d9d9 !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .el-upload:hover {
            border-color: #409EFF;
        }
    }
    .el-icon-plus {
        font-size: 28px;
        color: #8c939d;
        width: 118px;
        height: 118px;
        line-height: 118px;
        text-align: center; 
    }
}
.form-tips-words-color{
  color: #C0C4CC;
}
.form-tips-words-font-size{
	font-size: 12px;
}

.search-form{
	border-radius: 5px;
}
</style>
