import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      acLists:[],
      dreamList:[],
      targetList:[],
      tagList:[],
      menuList:[],
      main_title:[],
      // token:'',
      // topNavIndex:''
      curCoid:'',     //当前企业id
      companyList:[],   //企业列表,
  },
  mutations: {
    setMainTitle(state,value){
      state.main_title =  value
    },
    addAcItems(state,value){
        state.acLists.push(value)
    },

    editDreamList(state,list){
      state.dreamList =  list
    },

    editTargetList(state,value){
      state.targetList.push(value)
    },
    setTargetList(state,value){
      state.targetList = value
    },
    editTagList(state,value){
      state.tagList.push(value)
    },
    menuList(state,value){
      state.menuList =  value
    },
    setCurCoid(state,value){
        state.curCoid =  value
    },
    setCompanyList(state,list){   //写入企业列表
        state.companyList =  list
    }
    // setToken(state,token){
    //     state.token   = token
    //     localStorage.token  = token
    // },
    // setTopNavIndex(state,value){  //存储选中的导航(顶级)
    //     state.topNavIndex =  value
    // }
  },
  actions: {
  },
  modules: {
  }
})
