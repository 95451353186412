<template>
    <div class="page">
        <div class="bg">
            
        </div>
        <div class="content">
            <div class="left-box">
                 <img src="/images/login-left.png">
            </div>   
            <div class="form-box">
                    <ul>
                        <li :class="{active:!loginSwitch}"   @click="handleSwitch(0)">账号登录</li>
                        <li :class="{active:loginSwitch}" @click="handleSwitch(1)">扫码登录</li>
                    </ul>
                 <el-form ref="form" :model="form" v-if="!loginSwitch">  
                        <el-form-item>
                           <el-input placeholder="账号" v-model="name" @keypress.native.enter="login">
                           </el-input>
                        </el-form-item>
                        <el-form-item>
                           <el-input placeholder="密码"  v-model="password" type='password'  @keypress.native.enter="login">
                           </el-input>
                        </el-form-item>
                        <el-form-item class='remember'>
                            <!-- 默认7天内记住账号密码 -->
                            <el-checkbox v-model="rememberStatus">记住密码</el-checkbox>
                        </el-form-item>
                        <el-row class="btn">
                           <el-button type="warning" @click="login()"  class="login">登录</el-button>
                        </el-row>
                        <div class="tips">如果忘记密码，请和管理员联系</div>
                </el-form>
                <template v-else>
                    <div style="text-align:center;">
                        <el-select v-model="coid" style="width:212px;margin-top:10px;" @change="changeCode" placeholder="请选择">
                            <el-option v-for="(item,index) in companyList" :key="index" :value="item.id" :label="item.title"></el-option>
                        </el-select>
                    </div>
                    <div class="qrcode-box">
                        <div class="qrcode"  id="weChat">
                            <!-- <img src="/images/test_qrcode.jpg"> -->
                            <el-button style="margin-top:80px;font-size:12px;" type="danger" round>选择企业获取二维码</el-button>
                        </div>
                        <div class="tips">请使用企业微信扫描二维码登录</div>
                         <!-- <div class="tips">请使用微信【扫一扫】扫码登录</div>      -->
                    </div>
                </template>
            </div>    
        </div>
    </div>
</template>
<script>
import axios from "axios"
import { Message } from "element-ui"
import store from "../store"
export default {    
    store,
    data() {
      return {
           name:'',
           password:'',
           loginSwitch:0,
           rememberStatus:false,
           coid:'',
           form:{
               name:'',
               password:''
           },
           companyList:[]
      }
   },
   created(){ 
        this.getCookie();
        this.getCompanyList();
   },
   mounted(){
   },
   methods:{
       //获取登录二维码
       changeCode(val){
           if (val > 0) {
                this.$api.get('login/getCompanyInfo',{'id':val},res=>{
                    if (res.code == 200) {
                        const s= document.createElement('script') 
                        s.type  = 'text/javascript'
                        s.src = 'https://rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js'
                        const wxElement = document.body.appendChild(s)
                        wxElement.onload =  function(){
                            window.WwLogin({
                                id:'weChat',
                                appid:res.data.appid,
                                agentid:res.data.agentid,
                                redirect_uri:res.data.redirect_uri,
                                state:res.data.state,
                                href:'data:text/css;base64,ICAgIC5pbXBvd2VyQm94ew0KICAgICAgICB3aWR0aDogMjAwcHg7DQogICAgfQ0KICAgIC5pbXBvd2VyQm94IC50aXRsZXsNCiAgICAgICAgZGlzcGxheTogIG5vbmU7DQogICAgfQ0KICAgIC5pbXBvd2VyQm94IC5pbmZvew0KICAgICAgICBkaXNwbGF5OiBub25lOw0KICAgIH0NCiAgICAuaW1wb3dlckJveCAucXJjb2Rlew0KICAgICAgICB3aWR0aDogMjAwcHg7DQogICAgfQ0KICAgIC5pbXBvd2VyQm94IC53cnBfY29kZXsNCiAgICAgICAgbWFyZ2luOjVweCAwcHggMHB4IDVweCAhaW1wb3J0YW50Ow0KICAgIH0='
                            })
                        }
                    }
                })
           }
          
       },
       //获取现有企业
       getCompanyList(){
           this.$api.get('Login/getCompanyList',null,res=>{
               if (res.code == 200) {
                   this.companyList =  res.data
               }
           })
       },

       //获取cookie登录信息
       getCookie(){
            let cookie  = document.cookie.split(';')
            for (let i = 0; i < cookie.length; i++) {
                var arr2 = cookie[i].split("=")
                if (arr2[0] == ' remember_auth') {  //记住密码
                    var jsonStr =  decodeURIComponent(arr2[1])  //解码
                    var obj = JSON.parse(jsonStr)   //转json对象
                    this.name =  obj['nickname']
                    this.password = obj['password']
                    this.rememberStatus =  true
                }
            }
       },
        //登录
      login(){
            if(this.name == ''){   
               this.$message.error('用户名不能为空')
               return
            }
            if(this.password == ''){
               this.$message.error('密码不能为空')  
               return 
            }
            this.$api.post('Login/index',{'nickname':this.name,'password':this.password,'remember_status':this.rememberStatus},res=>{
               if(res.code == 200){
                    // store.commit('setToken',res.data.token)
                    this.$message.success(res.msg) 
                    localStorage.setItem('admin_id',res.data.id)
                    localStorage.setItem('name',this.name) 
                    localStorage.setItem('password',this.password)
                    localStorage.setItem('coid',res.data.coid)
                    this.$router.push('/Index/index') 
               }else{
                //   this.$message({ 
                //      message:res.msg,
                //      type:'error'
                //   })
                this.$message.error(res.msg)
               }
          })
        //     this.$api.post('Login/index',{'nickname':this.name,'password':this.password,'remember_status':this.rememberStatus},res=>{
        //        if(res.code == 200){
        //             store.commit('setToken',res.data.token)
        //             this.$message.success(res.msg)
        //             localStorage.setItem('name',this.name)
        //             localStorage.setItem('password',this.password)
        //             this.$router.push('/Index/index')
        //        }else{
        //           this.$message({
        //              message:res.msg,
        //              type:'error'
        //           })
        //        }
        //   })
      },
      handleSwitch(number){
        //   console.log(number)
          this.loginSwitch =  number
      }
   }
}
</script>
<style  lang="scss">
    .page{
        height: 100%;
         background: #fff1f1;
        ul{
            // padding: 0px;
            text-align: center;
            padding-top: 30px;
            li{
                color: #2f2f2f;
                list-style-type: none;
                display: inline-block;
                font-size: 18px;
                // padding-right: 50px;
                margin-right: 50px;
            }
            // li:active{
            //     border-bottom: 5px solid #ff0025;
            // }
            .active{
                 border-bottom: 5px solid #ff0025;
            }
        }
       
        .bg{
            background: url('/images/login-bg.png') no-repeat;
            background-size: 100%;
            width: 100%;
            height: 100%;
        //    filter: blur(10px);
        }
        .content{
            display: flex;
            // height: 600px;
            position: absolute;
            // background: blue;
            top: 150px;
            left: 16%;
            .left-box{
                width: 600px;
                height: 600px;
                // background: #ff9482;
                // border: 1px solid black;
                img{
                    width: 100%;
                    height:100%;
                }
            }
            .form-box{
                width: 433px;
                height: 445px;
                background-color: #ffffff;
                box-shadow: 0px 4px 12px 0px 
                    rgba(136, 7, 7, 0.12);
                border-radius: 10px;
                margin:40px 0px 0px 100px;
                position: relative;
                .el-form{
                    margin-top: 35px;
                    text-align: center;
                    .el-input input{
                        width: 345px;
                        height: 41px;
                        line-height: 41px;
                    }
                }
                .remember .el-form-item__content{
                    text-align:left;
                    left:45px;
                }
              
                .el-checkbox__inner{
                    border-color: #ff685d !important;
                }
                .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                    background: #ff685d;
                }
                .el-checkbox__label, .el-checkbox__input.is-checked+.el-checkbox__label{
                    color: #7a7f99;
                }
                .login{
                    width: 345px;
                    height: 45px;
                    background-image: linear-gradient(90deg, 
                        #ff001e 0%, 
                        #ff6b5f 100%);
                    border-radius: 22px;
                }
                .tips{
                    	width: 433px;
                        height: 55px;
                        line-height: 55px;
                        position: absolute;
                        bottom: 0px;
                        background-color: #fff6f7;
                        border-radius: 10px;
                        font-size: 14px;
                        color: #f47f7f;
                }
                .qrcode-box{
                    text-align: center;
                    .qrcode{
                        width: 210px;
                        height: 210px;
                        display: inline-block;
                        border: 1px solid #cccccc;
                        margin-top: 20px    ;
                         img{
                            width: 200px;
                            height: 200px;
                            margin:  5px auto;
                        }
                    } 
                   
                }
            }
        }
    }

    //以下为二维码样式跳转
    // .impowerBox{
    //     width: 200px;
    // }
    // .impowerBox .title{
    //     display:  none;
    // }
    // .impowerBox .info{
    //     display: none;
    // }
    // .impowerBox .qrcode{
    //     width: 200px;
    // }
    // .impowerBox .wrp_code{
    //     margin:5px 0px 0px 5px !important;
    // }
</style>