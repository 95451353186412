<template>
    <div>
        <el-empty description="页面未找到"></el-empty>
    </div>
</template>
<script>
export default {
   data() {
      return {
      }
   },
   created(){
   },
   mounted(){
   },
   methods:{
   }

}
</script>
<style scoped>
</style>