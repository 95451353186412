import { data, param } from 'jquery'
import Vue from 'vue'
import store from '../store'
var axios  = require('axios')

function Axios(method,url,params,success,fail){
    url =  '/api/admin/'+url
    axios({
        method:method, 
        url:url, 
        data:method == 'POST' || method == 'PUT' ? params :null,
        params:method ==  'GET' || method == 'DELETE' ? params :null,
        withCredentials:true,
        headers:{
            'token':localStorage.token 
        }
    }).
    then(function(res){  
        if(res.data.code == 401){           //登录失效 返回登录页面
            // window.alert(res.data.msg)
            window.location = '/'
        }
        if(res.data.code == 200){
             success(res.data)
        }else{
            if(fail){
                fail(res.data)
            }else if(res.data.msg){
                window.alert(res.data.msg)
            }
        }
    })
    .catch(function(err){
        // let res =  err.response
        // console.log(err);
        if(err){
            window.alert('api error, HTTP CODE :'+err)
        }
    })
}

function getBase64(file){
    return new Promise(function(resolve,reject){
        let reader =  new FileReader()
        let imgRes = ''
        reader.readAsDataURL(file)
        reader.onload =  function(){
            imgRes =  reader.result
        }
        reader.onerror  = function(err){
            reject(err)
        }
        reader.onloadend =  function(){
            resolve(imgRes)
        }
    })
}

export default{
    get:function(url,params,success,fail){
        return Axios('GET',url,params,success,fail)
    },
    post:function(url,params,success,fail){
        return Axios('POST',url,params,success,fail)
    },
    put:function(url,params,success,fail){
        return Axios('PUT',url,params,success,fail)
    },
    delete:function(url,params,success,fail){
        return Axios('DELETE',url,params,success,fail)
    },
    getBase64:function(file){
        return getBase64(file)
    }
}